import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { removeUserSession } from '../../utilis/common';
import swal from 'sweetalert';
import { logoutUser } from '../../services/apiServices';

const Logout = () => {
    const history = useNavigate();
    const hasFetched = useRef(false);

    useEffect(() => {
      if (hasFetched.current) return;
      hasFetched.current = true;
        logout();
    }, [history]);

    const logout = async () => {
        const result = await logoutUser();
        if (result.error) {
          swal({ text: result.error, icon: "warning", dangerMode: true });
          removeUserSession(); // Remove user session after successful logout
          history('/login');
        } else {
          swal("Success!", result.message, "success");
          removeUserSession(); // Remove user session after successful logout
          history('/login');
        }
    }

    return null; // Component does not render anything
}

export default Logout;
