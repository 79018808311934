import React from 'react';

const Footer = () => {
    return (
        <footer className="footer text-white text-center">
            <div className="f-container py-2">
                <div className='p-2'>&copy; {new Date().getFullYear()} Your Company. All rights reserved.</div>
            </div>
        </footer>
    );
};

export default Footer;
