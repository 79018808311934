
import React from "react";
import * as FaIcons from 'react-icons/fa';
import { useNavigate} from "react-router-dom";


const Title = (props) => {
    const history = useNavigate();

    return(
        <>
        <div className="row pr-2">
        <div className={`${props.titleCol ? props.titleCol : 'col-lg-4 col-5'}`}>
            {props.back && 
            <div className="pt-0 ">
            <a className="back-icon" onClick={() => props.backUrl ? history( '/main-settings') : history(-1)}>
            <FaIcons.FaArrowAltCircleLeft size={18}/><span className="back-text pl-lg-1">back</span>
            </a></div>
            }
            <h5 className={`text-left font-monst ${!props.back ? 'pt-2' : ' pl-lg-3' }`}>{props.title}</h5>
            </div>
            {props.btn ? 
            <div className={`${props.btnCol ? props.btnCol : 'col-lg-6'}`}>
            <button  type="button"  
            onClick={() => props.btnUrl ? history(props.btnUrl) : props.parentCallback() }
            className={`${props.btnClass ? props.btnClass: ''}`}>{props.btnText}</button>
            </div>
            : ''}
        </div>
        
        </>
    ) 
}
export default Title;