// Header.js
import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './header.css';
import '../../styles/global.css'
import { FaHome, FaSignOutAlt } from 'react-icons/fa';
import logo from '../../assets/plazaa.png';
import swal from 'sweetalert';
import { FaBuildingColumns, FaBuildingLock, FaBuildingShield, FaBuildingUser } from 'react-icons/fa6';

const Header = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    useEffect(() => {
    }, []);
  
    const handleLogout = () => {
      swal({
        title: "Logging out",
        text: "Are you sure you want to log out?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willLogout) => {
        if (willLogout) {
          navigate('/logout');  // Assuming you use `useNavigate()` hook from 'react-router-dom'
        }
      });
    };
  //   const logout = async () => {
  //     const result = await logoutUser();
  //     if (result.error) {
  //       swal({ text: result.error, icon: "warning", dangerMode: true });
  //     } else {
  //       swal("Success!", result.message, "success");
  //       removeUserSession(); // Remove user session after successful logout
  //       history('/login');
  //     }
  // }
  
  return (
    user ?
    <header className="header row align-items-stretch mb-0 pb-0 h-bg">
    <div className='row py-2 px-lg-4' style={{background:'#343a40'}}>
    <div className='col-lg-10 col-8'>
      <div id="logo" className="sidebar-brand-text"> 
      <img src={logo} className='plaza-logo' alt="Company logo" width="70"/>
      </div>
    </div>
    <div className='col-lg-2 col-4 d-flex align-item-center'>
    <div className='text-right w-100'>
    <button className='btn btn-info white px-4 py-2' onClick={handleLogout}>
    <span className='pe-lg-1 py-2 acc-label'>LOGOUT</span>
    <FaSignOutAlt size={17} className='dash-icon' /></button>
    </div>
    </div>
    </div>
    <div className='row mt-lg-3 mt-1 px-lg-4 px-2'>
    <div className="white w-100 row">
    <NavLink to="/dashboard" className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
     <FaHome size={16} /> 
      <span className='ps-2  px-2'> Dashboard</span>
    </NavLink>
    <NavLink to={`/view-unit-report`} className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
      <FaBuildingColumns size={16}  />
      <span className='ps-2'>Unit Report</span>
    </NavLink>
    <NavLink to={`/view-rent-report`} className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
      <FaBuildingColumns size={16}  />
      <span className='ps-2'>Rent Report</span>
    </NavLink>
    <NavLink to={`/view-flats/${1}`} className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
      <FaBuildingColumns size={16}  />
      <span className='ps-2'>Ahmad Plaza</span>
    </NavLink>
    <NavLink to={`/view-flats/${2}`} className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
      <FaBuildingShield size={16} />
      <span className='ps-2'>Ahsan Plaza</span>
    </NavLink>
    <NavLink to={`/view-flats/${3}`} className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
      <FaBuildingUser size={16} />
      <span className='ps-2 '>Ikram Plaza</span>
    </NavLink>
    {/* <NavLink to="/setting" className={({ isActive }) => isActive ? "no-underline menu-item active-tab" : "no-underline menu-item"}>
      <FaBuildingUser size={16} />
      <span className='ps-2 '>Settings</span>
    </NavLink> */}
  </div>

      </div>

    </header>:''
  );
};

export default Header;
