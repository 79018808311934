
import React, { useEffect, useState } from 'react';
import Title from '../Title';
import '../../styles/global.css'
import swal from 'sweetalert';
import { useNavigate, useParams } from "react-router-dom";
import { addFlat } from '../../services/apiServices';

const AddFlat = () => {
    const navigate = useNavigate();
    const { pid } = useParams();
    const [name, setName] = useState('');
    const [rent, setRent] = useState('');
    const [advancePayment, setAdvancePayment] = useState('');
    const [sweeperCharges, setSweeperCharges] = useState('');
    const [ratePerUnit, setRatePerUnit] = useState('');
    const [rentDate, setRentDate] = useState('');
    const [notes  ,setNotes] = useState('');
    const [nameError, setNameError] = useState('');

    useEffect(() => {
    }, []);

    const handleSubmit = async () => {
        if(!name){
            setNameError('Please enter name')
            return false
          } else{
            const data = {
                name:name,
                pid: pid,
                rent: rent,
                advancePayment: advancePayment,
                sweeperCharges: sweeperCharges,
                ratePerUnit: ratePerUnit,
                rentDate: rentDate
            }
            console.log(data);
            let result = await addFlat( {data});
            if (result.error) {
                swal({ text: result.error, icon: "warning", dangerMode: true });
            } else {
                swal("Success!", result.message, "success");
                navigate(-1)
            }
          }
    }

    return (
        <>
        <div className='px-lg-4 px-2 mb-5'>
        <Title back={true}/>
        <div className=' py-lg-0 bg-white border shadow'>
        <h5 className='acc-label white text-center s-bg mb-0 py-2'>Add New Flat</h5>
        <div className='row px-3 mt-2 px-lg-5 px-3 py-lg-2'>
        <div className="form-group col-lg-4 col-12">
            <label className="font-f-roboto size-18 mb-0">Flat/Shop No</label>
            <input type="text" name="name" className="form-control acc-form w-90 mb-3 mb-lg-4 " onChange={(e) => setName(e.target.value)} 
            placeholder="Please Enter" />
             {nameError && <><small className="error">{nameError}</small></>}
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Total Rent</label>
            <input type="number" name="name" className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setRent(e.target.value)} 
            placeholder="Please Enter" />
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Advance Payment</label>
            <input type="number" name="name" className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setAdvancePayment(e.target.value)} 
            placeholder="Please Enter" />
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Sweeper Charges</label>
            <input type="number" name="name" className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setSweeperCharges(e.target.value)} 
            placeholder="Please Enter" />
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Rate Per Unit</label>
            <input type="number" name="name" className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setRatePerUnit(e.target.value)} 
            placeholder="Please Enter" />
        </div>

        {/* <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Advance Received</label>
            <input type="number" name="name" className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setAdvanceReceived(e.target.value)} 
            placeholder="Please Enter" />
        </div> */}

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Rent Out Date</label>
            <input  type="text"
                name="rentdate"
                className="date-input w-90 mb-2 mb-lg-4"
                onFocus={(e) => e.target.type = 'date'}
                onBlur={(e) => {
                    if (!e.target.value) e.target.type = 'text';
                }}
                onChange={(e) => setRentDate(e.target.value)}
                placeholder="Please Enter Date"
                value={rentDate} />
        </div>

        <div className="form-group col-lg-6 col-12 mb-3">
            <label className="font-f-roboto  size-18 mb-0">Notes</label>
            <textarea className="textarea form-control acc-form" onChange={(e) => setNotes(e.target.value)} 
           placeholder="Please Enter" rows="4" cols="60"></textarea>
        </div>

        <div className='d-flex'>
                <button className='btn btn-success my-4 mt-0 px-5 w-100' onClick={() => handleSubmit()}>Save Flat</button>
            </div>
        </div>

        </div>
        </div>
        </>
        )
}
export default  AddFlat