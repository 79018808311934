import apiManager from "../api/apiManager";

export const createUser = async (userData) => {
    const response = await apiManager.post('/create-user', userData);
    if (response.success) {
      return { data: response.data, error: null, message: response.data.message };
    } else {
      return { data: null, error: response.message || 'An error occurred' };
    }
};

export const loginUser = async (userData) => {
  const response = await apiManager.post('/login-user', userData);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};

export const logoutUser = async () => {
  const response = await apiManager.get('/logout-user');
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const getPUnitRate = async (pid) => {
  const response = await apiManager.get(`/get-unit-rate/${pid}`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};

export const createUnitReport = async (userData) => {
  const response = await apiManager.post(`/create-unit-report`, userData);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const createRentReport = async (userData) => {
  const response = await apiManager.post(`/create-rent-report`, userData);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const addFlat = async (userData) => {
  const response = await apiManager.post('/add-flat', userData);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const editFlat = async (userData) => {
  const response = await apiManager.post(`/edit-flat`, userData);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const flatDetail = async (flatid) => {
  const response = await apiManager.get(`/get-flat-detail/${flatid}`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const flatStatus = async (flatid,status) => {
  const response = await apiManager.get(`/flat-status/${flatid}/${status}`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const getFlats = async ({pid, currentPage = 1, perPage =10}) => {
  const response = await apiManager.get(`/get-flats/${pid}?page=${currentPage}&perPage=${perPage}`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const getUnitReport = async () => {
  const response = await apiManager.get(`/get-unit-report`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const getRentReport = async () => {
  const response = await apiManager.get(`/get-rent-report`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const checkToken = async () => {
  const response = await apiManager.get(`/check-token`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const getMonthlyReport = async () => {
  const response = await apiManager.get(`/get-monthly-report`);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const newMotorReport = async (userData) => {
  const response = await apiManager.post('/create-motor-report', userData);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};
export const updateReport = async (userData) => {
  const response = await apiManager.post('/update-unit-report', userData);
  if (response.success) {
    return { data: response.data, error: null, message: response.data.message };
  } else {
    return { data: null, error: response.message || 'An error occurred' };
  }
};

