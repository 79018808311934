
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import swal from 'sweetalert';
import { newMotorReport  } from '../../services/apiServices';

class InquiryModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            presentMotorUnit:0,
            month:'',
            totalUnit:0,
            motorAmount:0,
            modifyPrevious: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleModal(){
         this.resetValue();
         this.props.parentCallback('cancel'); 
    }
    resetValue = () => {
        this.setState({presentMotorUnit:0,month:'', totalUnit:0,motorAmount:0, modifyPrevious: false, presentMotorUnit:''})
    } 

    handleChange(event) {
        let present_unit;
        const total_unit = event.target.value;
        if(this.state.previousMotorUnit){
            present_unit = total_unit - this.state.previousMotorUnit;
        } else{
            present_unit = total_unit - this.props.previousUnit;
        }
        present_unit = Math.abs(present_unit);
         // Check if the present unit is negative
        if (event.target.value < 0) {
            swal({ text: 'Enter number more than 0', icon: "warning", dangerMode: true });
            this.setState({presentMotorUnit: 0})
            this.setState({presentMotorUnit: 0, totalUnit:0, unitAmount:0 })
        } else{
            let unitAmount = present_unit * this.props.unitRate;
            this.setState({presentMotorUnit: present_unit, totalUnit: event.target.value, motorAmount: unitAmount})
        }
        
    }   
    changePreviousValue = (e) => {
        e.preventDefault();
        this.setState({modifyPrevious: true})
    } 
    cancelPreviousValue = (e) => {
        e.preventDefault();
        this.setState({modifyPrevious: false, previousMotorUnit:''})
    }  
    
    handleSubmit = async () => {
        const data ={
            presentMotorUnit: this.state.presentMotorUnit,
            previousMotorUnit: this.state.previousMotorUnit ? this.state.previousMotorUnit : this.props.previousUnit,
            month: this.state.month,
            totalUnit: this.state.totalUnit,
            motorAmount:this.state.motorAmount,
            pid: this.props.pid,
            unitRate: this.props.unitRate,
            type: this.props.type
        }
        console.log(data)
        let result = await newMotorReport( {data});
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            swal("Success!", result.message, "success");
            this.props.parentCallback('created');
        }
    }
    
  render(){ 
    const currentDate = new Date();
    // Calculate the date for 4 months ago
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(currentDate.getMonth() - 4);
    // Get dates in the format YYYY-MM-DD
    const minDate = monthsAgo.toISOString().split('T')[0];
    const maxDate = new Date(currentDate.getFullYear(), 11, 31).toISOString().split('T')[0]; // End of the current year

      return(
          <>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered  show={this.props.show} onHide={()=>this.handleModal()}>  
                <Modal.Header closeButton={this.props.endButton == true ? "" : true}>
                <h5 className="text-center col-11 acc-label mb-0">New Report for {this.props.type}</h5>
                </Modal.Header>  
                <Modal.Body className="ml-2 mr-2">
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row p-2">
            
                <div className="form-group col-lg-6">
                <label className="acc-label">Previous Unit</label>
                {(this.state.modifyPrevious === 'true' ||  this.state.modifyPrevious === true) ?
                 <input type='number' value={this.state.previousMotorUnit} onChange={(e) => this.setState({previousMotorUnit:e.target.value})} className="form-control w-lg-90"/>
                :
                <input type='number' value={this.props.previousUnit} className="form-control w-lg-90" disabled/>}
                </div>

                <div className='form-group col-lg-6'>
                <label className="white">Previous Unit</label>
                {this.state.modifyPrevious === false ?
                <button className='btn btn-info' onClick={(e) => this.changePreviousValue(e)}>Change Previous Unit</button>
                :
                <button className='btn btn-danger' onClick={(e) => this.cancelPreviousValue(e)}>Cancel</button>}
                </div>

                <div className="form-group col-lg-6">
                <label className="acc-label">Unit Rate</label>
                <input type='number' value={this.props.unitRate} className="form-control w-lg-90" disabled/>
                </div>

                <div className="form-group col-lg-6">
                <label className="acc-label">Total Motor Unit</label>
                <input type='number' min="0" value={this.state.totalUnit} name="totalUnit" onChange={this.handleChange} className="form-control acc-form w-lg-90"  placeholder='Please enter'/>
                </div>

                <div className="form-group col-lg-6">
                <label className="acc-label">Present Motor Unit</label>
                <input type='number' min="0" name="presentMotorunit" value={this.state.presentMotorUnit} className="form-control w-lg-90" disabled/>
                </div>

                <div className="form-group col-lg-6">
                <label className="acc-label">Motor Amount</label>
                <input type='number' value={this.state.motorAmount} className="form-control w-lg-90" disabled/>
                </div>

                <div className="form-group col-12">
                <label className="acc-label">Inquiry Month</label>
                <input type='date' value={this.state.month} className="form-control w-lg-90" 
                onChange={(e) => this.setState({month: e.target.value})}
                min={minDate} // Set the minimum date to two months ago
                max={maxDate} // Set the maximum date to the end of the current year
                disabled={this.props.existingMonths.includes(this.state.month)}
                />
                </div>

                </div>
                </form>
                </Modal.Body>  
                <Modal.Footer>  
                {this.state.presentMotorUnit && this.state.month ? 
                <Button className="btn btn-success w-100" onClick={this.handleSubmit}>Create Unit Report</Button>  :''}
                <Button className="btn btn-danger" onClick={()=>this.handleModal()}>Cancel</Button>
                </Modal.Footer>  
            </Modal>  
          </>
      )
  }
}
export default InquiryModel