import React, { useEffect, useState } from 'react';
import Title from './Title';
import '../styles/global.css'
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";

const Setting = () => {
    const navigate = useNavigate();
    const [setting, setSetting] = useState([]);

    useEffect(() => {
    }, []);

  
  
    const handleMotorChange = (index, newMotorValue) => {
        const updatedSetting = setting.map((item, i) => {
            if (i === index) {
                return { ...item, motor: newMotorValue }; // Update the motor value for the specific item
            }
            return item; // Return the other items unchanged
        });
        // Update the state
    };
    const goToPage = (id) => {

    }


    return (
        <>
        <div className='px-lg-4 px-3 mb-5'>
        <Title title={"Setting"} btn={true}  btnText="Add New Record" 
         btnClass="float-right btn btn-info white"
         btnUrl="/add-entry"
         btnCol="col-8 text-right py-2"  />
        {setting.map((value, index) => (
        <div key={value.id} className='row mx-lg-5 bg-white shadow border mb-4'> 
        <h5 className='acc-label white text-center s-bg mb-0 py-3'>{value.name}</h5>
        <div className='row px-4 py-3 pb-0'>

        <div className='col-lg-6'>
            <div className="row">
            <label for="username" className='acc-label bold col-lg-3 col-4 py-2'>Motor Rate:</label>
            <div className='col-lg-8 col-8'>
            <input type='number' value={value.motor} className='form-control acc-form' onChange={(e) => handleMotorChange(index, e.target.value)} placeholder='Enter amount'/>
            </div>
            </div>
        </div>

        <div className='col-lg-6'>
            <div className="row">
            <label for="username" className='acc-label bold col-lg-3 col-4 py-2'>Unit Rate:</label>
            <div className='col-lg-8 col-8'>
            <input type='number' className='form-control acc-form ' name="unit" placeholder='Enter unit'/>
            </div>
            </div>
            </div>
        </div>
            <div className='d-flex justify-content-between px-3 mb-3'>
                <button className='btn btn-success m-lg-4 mt-0 px-lg-5'>Save Changes</button>
                <button className='btn btn-secondary m-lg-4 mt-0 px-lg-5' onClick={() => goToPage(value.id)}>View Flats</button>
            </div>
        </div>))}
       
        </div> 
        </>
    )
}
export default Setting