import React, { useEffect,  useState } from 'react';

const Dashboard = () => {
    const[pid, setPid] = useState('');

    useEffect(() => {
    }, []);


    return(
        <>
        <div className='px-lg-4 px-2 mb-5'>
            Dashboard
        </div>
      
        </>
    )
}
export default Dashboard;