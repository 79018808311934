export const main_url = () => {
    return 'https://core.wrkinfo.com/public';
    // return 'http://127.0.0.1:8000';
}
export const getUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
}
export const setUserSession = (token, user) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user_id", JSON.stringify(user['id']));
    localStorage.setItem("user", JSON.stringify(user));
}
export const removeUserSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.clear()
}
export const getToken = () => {
    return localStorage.getItem("token") || null;
}
