import React, { useRef } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';

const GeneratePDF = ({urduText}) => {
  const urduTextRef = useRef(null);

  const generateUrduPDF = async () => {
    try {
      const element = urduTextRef.current;
      const scale = 3; // Increase the scale for higher resolution

      // Use html2canvas with increased scale
      const canvas = await html2canvas(element, {
        useCORS: true,
        scale: scale,
      });

      const imgData = canvas.toDataURL('image/png');

      // Calculate the width and height based on the element's size
      const imgWidth = element.offsetWidth;
      const imgHeight = element.offsetHeight;

      // Define the PDF content
      const docDefinition = {
        content: [
          {
            image: imgData,
            width: imgWidth, // Set the image width to match the element's width
            alignment: 'center',
            margin: [0, 0, 0, 20],
          },
        ],
      };

      // Generate and download the PDF
      pdfMake.createPdf(docDefinition).download('urdu_example.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div>
      {/* Hidden div with Urdu text */}
      <div
        ref={urduTextRef}
        style={{
          fontSize: '18px', // Increase font size for better clarity
          fontFamily: 'JameelNooriNastaleeq, Arial, sans-serif',
          direction: 'rtl',
          textAlign: 'right',
          width: '500px',
          position: 'absolute',
          top: '-9999px', // Hide the element off-screen
        }}
      >
    {urduText}
      </div>
      <button onClick={generateUrduPDF}>Download Urdu PDF</button>
    </div>
  );
};

export default GeneratePDF;
