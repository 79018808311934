import React, { useEffect, useRef, useState } from 'react';
import InquiryModel from '../Plazas/inquiryModel';
import swal from 'sweetalert';
import { getPUnitRate, getUnitReport, updateReport } from '../../services/apiServices';
import moment from 'moment';
import ExcelExport from '../excelExport';
import DateModel from '../Plazas/dateModel';
import MultiTableExcelExport from '../exportAllTables';
import { Link } from "react-router-dom";

const UnitReport = () => {
    const[newInquiry, setNewInquiry] = useState(false);
    const[showModel, setShowModel] = useState(false);
    const[type, setType] = useState(false);
    const[unitRate, setUnitRate] = useState('');
    const[pid, setPid] = useState('');
    const[reportP1, setReportP1] = useState([]);
    const[reportP2, setReportP2] = useState([]);
    const[reportP3, setReportP3] = useState([]);
    const[monthP1, setMonthP1] = useState('');
    const[monthP2, setMonthP2] = useState('');
    const[monthP3, setMonthP3] = useState('');
    const[motorP1, setMotorP1] = useState([]);
    const[motorP2, setMotorP2] = useState([]);
    const[motorP3, setMotorP3] = useState([]);
    const[previousUnit, setPreviousUnit] = useState('');
    const hasFetched = useRef(false);
    const[existingMonths, setExistingMonths] = useState('')
    const [headers] = useState([ 
        {title: 'FlatNo' , dataKey: "name",key:1, align: "text-center"},
        {title: 'Total_Unit' , dataKey: "total_unit", align: "text-center",key:5},
        {title: 'Prev_Unit' , dataKey: "previous_unit", align: "text-center",key:2},
        {title: 'Rate' , dataKey: "unit_rate", align: "text-center",key:4},
        {title: 'Units' , dataKey: "unit", align: "text-center",key:6},
        {title: 'Amount' , dataKey: "unit_amount", align: "text-center",key:7},
        {title: 'Motor_Bill' , dataKey: "motor_bill", align: "text-center",key:8},
        {title: 'Prev_Bal' , dataKey: "previous_balance", align: "text-center",key:9},
        {title: 'Total_Amount' , dataKey: "total_amount", align: "text-center",key:10},
        {title: 'Paid_Amount' , dataKey: "paid_amount", align: "text-center",key:11},
        {title: 'Paid_Date' , dataKey: "paid_date", align: "text-center",key:12},
        {title: 'Remaining' , dataKey: "remaining_amount", align: "text-center",key:13},
    ]);
    const [headersP3] = useState([ 
        {title: 'FlatNo' , dataKey: "name",key:1, align: "text-center"},
        {title: 'Total_Unit' , dataKey: "total_unit", align: "text-center",key:5},
        {title: 'Prev_Unit' , dataKey: "previous_unit", align: "text-center",key:2},
        {title: 'Rate' , dataKey: "unit_rate", align: "text-center",key:4},
        {title: 'Units' , dataKey: "units", align: "text-center",key:6},
        {title: 'Amount' , dataKey: "unit_amount", align: "text-center",key:7},
        {title: 'Prev_Bal' , dataKey: "previous_balance", align: "text-center",key:7},
        {title: 'Total_Amount' , dataKey: "total_amount", align: "text-center",key:9},
        {title: 'Paid_Amount' , dataKey: "paid_amount", align: "text-center",key:10},
        {title: 'Paid_Date' , dataKey: "paid_date", align: "text-center",key:11},
        {title: 'Remaining' , dataKey: "remaining_amount", align: "text-center",key:12},
    ]);

    useEffect(() => {
        if (hasFetched.current) return;
        hasFetched.current = true;
        fetchReport();
    }, []);

    const fetchReport = async () => {
        let result = await getUnitReport();
        console.log('report',result.data)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            if(result.data.data){
                setReportP1(result.data.data['P1']);
                setReportP2(result.data.data['P2']);
                setReportP3(result.data.data['P3']);
                setMotorP1(result.data.data['motorP1']);
                setMotorP2(result.data.data['motorP2']);
                setMotorP3(result.data.data['motorP3']);
                setMonthP1(result.data.data['monthP1'])
                setMonthP2(result.data.data['monthP2'])
                setMonthP3(result.data.data['monthP3'])
            }
        }
    }

    const makeInquiry = async (pid, pname) => {
        let result = await getPUnitRate(pid);
        console.log('motorInquiry', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            setUnitRate(result.data.data['setting']['unit_rate'])
            if(result.data.data['previous_unit']){
                setPreviousUnit(result.data.data['previous_unit']['total_unit'])
            }
            setExistingMonths(result.data.data['existingMonths'])
            setType(pname);
            setPid(pid)
            setNewInquiry(true)
        }
    }
    const createUnitReport = async (pid, pname) => {
        setType(pname);
        setPid(pid);
        setShowModel(true);
    }

    const handleDateModelCallback = (type) => {
        if(type === 'cancel'){
            setShowModel(false);
            setType('');
        } else if('created'){
            setShowModel(false);
            fetchReport();
        }
    }

    const handleModelCallback = (type) => {
        if(type === 'cancel'){
            setNewInquiry(false);
            setType('');
        } else if('created'){
            setNewInquiry(false);
            fetchReport();
        }
    }
    const calculateUnit = (index, value, from) => {
        if(!value.total_unit || !value.unit_rate){
            swal({ text: 'Values missing', icon: "warning", dangerMode: true });
            return false;
        } else{
            if(from === 1){
                const updatedReport = [...reportP1];
                const unit= value.total_unit - value.previous_unit;
                let unitAmount = unit*value.unit_rate;
                updatedReport[index].present_unit = unit;
                updatedReport[index].unit_amount = unitAmount;
                let total_amount = unitAmount+value.motor_bill+updatedReport[index].previous_balance;
                updatedReport[index].total_amount = total_amount;
                setReportP1(updatedReport);
            } else if(from === 2){
                const updatedReport = [...reportP2];
                const unit= value.total_unit - value.previous_unit;
                let unitAmount = unit*value.unit_rate;
                updatedReport[index].present_unit = unit;
                updatedReport[index].unit_amount = unitAmount;
                let total_amount = unitAmount+value.motor_bill+updatedReport[index].previous_balance;
                updatedReport[index].total_amount = total_amount;
                setReportP2(updatedReport);
            } else if(from === 3){
                const updatedReport = [...reportP3];
                const unit= value.total_unit - value.previous_unit;
                let unitAmount = unit*value.unit_rate;
                updatedReport[index].present_unit = unit;
                updatedReport[index].unit_amount = unitAmount;
                let total_amount = unitAmount+value.motor_bill+updatedReport[index].previous_balance;
                updatedReport[index].total_amount = total_amount;
                setReportP3(updatedReport);
            }
        }    
    }
    const handlePreviousUnitChange = (index, value, from) => {
        if(from === 1){
            const updatedReport = [...reportP1];
            updatedReport[index].previous_unit = value;
            setReportP1(updatedReport);
        } else if(from === 2){
            const updatedReport = [...reportP2];
            updatedReport[index].previous_unit = value;
            setReportP2(updatedReport);
        } else if(from === 3){
            const updatedReport = [...reportP3];
            updatedReport[index].previous_unit = value;
            setReportP2(updatedReport);
        }
    }
    const handleTotalUnitChange = (index, value, previous, from) => {
        if(from === 1){
            const updatedReport = [...reportP1];
            updatedReport[index].calculate = true;
            updatedReport[index].total_unit = value;
            setReportP1(updatedReport);
            console.log(updatedReport)
        } else if(from === 2){
            const updatedReport = [...reportP2];
            updatedReport[index].calculate = true;
            updatedReport[index].total_unit = value;
            setReportP2(updatedReport);
            console.log(updatedReport)
        } else if(from === 3){
            const updatedReport = [...reportP3];
            updatedReport[index].calculate = true;
            updatedReport[index].total_unit = value;
            setReportP3(updatedReport);
            console.log(updatedReport)
        }

    }
    const handlePaidChange = (index, value, from) => {
        if(from === 1){
            const updatedReport = [...reportP1];
            if(value < 0){
                swal({ text: 'Enter Amount greater than 0', icon: "warning", dangerMode: true });
                return false;
            } else{
                updatedReport[index].paid_amount = value;
                updatedReport[index].newpaid = true;
                let amount = updatedReport[index].total_amount-updatedReport[index].paid_amount
                updatedReport[index].remaining_amount = amount;
                setReportP1(updatedReport);
            } 
        } else if(from === 2){
                const updatedReport = [...reportP2];
                if(value < 0){
                    swal({ text: 'Enter Amount greater than 0', icon: "warning", dangerMode: true });
                    return false;
                } else{
                    updatedReport[index].paid_amount = value;
                    updatedReport[index].newpaid = true;
                    let amount = updatedReport[index].total_amount-updatedReport[index].paid_amount
                    updatedReport[index].remaining_amount = amount;
                    setReportP2(updatedReport);
                }
        } else if(from === 3){
            const updatedReport = [...reportP3];
            if(value < 0){
                swal({ text: 'Enter Amount greater than 0', icon: "warning", dangerMode: true });
                return false;
            } else{
                updatedReport[index].paid_amount = value;
                updatedReport[index].newpaid = true;
                let amount = updatedReport[index].total_amount-updatedReport[index].paid_amount
                updatedReport[index].remaining_amount = amount;
                setReportP2(updatedReport);
            }
    }
    }
    const handlePaidDateChange = (index, value, from) => {
        if(from === 1){
            const updatedReport = [...reportP1];
            updatedReport[index].paid_date = value;
            setReportP1(updatedReport);
        } else if(from === 2){
            const updatedReport = [...reportP2];
            updatedReport[index].paid_date = value;
            setReportP2(updatedReport);
        } else if(from === 3){
            const updatedReport = [...reportP3];
            updatedReport[index].paid_date = value;
            setReportP3(updatedReport);
        }
    }
    const submitReport = async (from) => {
        let REPORT;
        if(from === 1){
            REPORT = reportP1;
        } else if(from === 2){
            REPORT = reportP2;
        } else if(from === 3){
            REPORT = reportP3;
        }
        const data ={
            report:REPORT,
        }
        console.log(data)
        let result = await updateReport({data});
        console.log('report-updated', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            swal("Success!", result.message, "success");
            fetchReport();
        }
    }

    return(
        <>
        <div className='px-lg-4 px-2 mb-5'>
        <div className='justify-content-between d-flex mt-3'>
        <MultiTableExcelExport 
        xlsxfilename={'UnitReport'}
        data1={reportP1}
        data2={reportP2}
        data3={reportP3} 
        date1={monthP1} 
        date2={monthP2} 
        date3={monthP3} 
        title={'UnitReport'} 
        motorP1={motorP1} 
        motorP2={motorP2} 

        />
        <Link to='/view-monthly-report'>
        <button className='btn btn-secondary px-4 py-2'>Monthly Report</button></Link>
        </div>
        {/* Ahmad Plaza */}
        <div className='mb-5 mt-3 border shadow bg-white'>
        <h4 className='text-center acc-label mb-0 py-2 border p-bg'>Ahmad Plaza {monthP1 ? '['+ moment(monthP1).format('D MMMM YYYY')+']' : ''}</h4>
        <div className='d-flex my-2 justify-content-end px-1'>
        {/* <ExcelExport xlsxfilename={'UnitReport'} header="UnitReport" data={reportP1} plaza={'Ahmad Plaza'} date={monthP1} /> */}
        <button className='btn btn-primary' onClick={() => makeInquiry(1,'Ahmad Plaza')}>New Unit Report</button>
        </div>
        <div className='table-responsive px-1'>
         <table id="eduapp" className="table table-bordered bg-white mb-2 ">
            <thead >
                <tr className='table-primary'>
                {headers.map(head => (
                <th id={head.dataKey} className={head.align} key={head.key}>
                {head.title}</th> ))}
                </tr>
            </thead>
            <tbody>
            {reportP1.map((value, index) =>
                <tr key={value.id}>
                <td className='text-center'>{value.name}</td>
                <td className='text-center w-auto'>
                <div className='d-flex'>
                    <input className={`form-control mb-0 me-2 ${value.calculate ? 'w-auto' : 'w-100'}`} value={value.total_unit || ''}
                    onChange={(e) => handleTotalUnitChange(index, e.target.value, value.previous_unit, 1)} />
                    {value.calculate && <button className='btn btn-info w-auto' 
                    onClick={() => calculateUnit(index, value, 1)}>Calculate</button>}
                </div>
                </td>
                <td className='text-center'>
                    <input type='number' className='form-control mb-0' value={value.previous_unit || ''}
                    onChange={(e) => handlePreviousUnitChange(index, e.target.value, 1)} />
               </td>
                <td className='text-center'>{value.unit_rate}</td>
                <td className='text-center'>{value.present_unit}</td>
                <td className='text-center'>{value.unit_amount}</td>
                <td className='text-center'>{value.motor_bill}</td>
                <td className='text-center'>{value.previous_balance}</td>
                <td className='text-center'>{value.total_amount}</td>
                <td className='text-center'> 
                    <input type='number' className='form-control mb-0' value={value.paid_amount || ''}
                    onChange={(e) => handlePaidChange(index, e.target.value, 1)} />

                </td>
                <td className='text-center'>{(value.newpaid === true || !value.paid_date)?
                    <input type='date' className='form-control mb-0' value={value.paid_date || ''}
                    onChange={(e) => handlePaidDateChange(index, e.target.value, 1)} />
                    :
                    moment(value.paid_date).format('D MMMM YYYY')}
                </td>
                <td className='text-center'>{value.remaining_amount}</td>
                </tr>
            )}
            <tr>
                <td className='bold text-center size-18'>Motor</td>
                <td className='text-center size-18 '>{motorP1 && motorP1.previous_unit}</td>
                <td className='text-center size-18'>{motorP1 && motorP1.unit_rate}</td>
                <td className='text-center size-18'>{motorP1 && motorP1.total_unit}</td>
                <td className='text-center size-18 '>{motorP1 && motorP1.unit}</td>
                <td className='text-center size-18 '>{motorP1 && motorP1.unit_amount}</td>
                <td className='text-center bold'>{motorP1 && motorP1.division}</td>
                <td className=''></td>
                <td className=''></td>
                <td className=''></td>
                <td className=''></td>
            </tr>
            </tbody>
         </table>
         
        </div>
        <div className='d-flex p-2 mb-5'>
            <button className='btn btn-success px-5 py-2' onClick={() => submitReport(1)}>Save Changes</button>
         </div>
        </div>
        {/* Ahsan Plaza */}
        <div className='my-5 border shadow bg-white'>
        <h4 className='text-center acc-label mb-0 py-2 border p-bg'>Ahsan Plaza {monthP2 ? '['+ moment(monthP2).format('D MMMM YYYY')+']' : ''}</h4>
        <div className='d-flex my-2 justify-content-end px-1'>
        {/* <ExcelExport xlsxfilename={'UnitReport'} header="UnitReport" data={reportP2}  plaza={'Ahsan Plaza'} date={monthP2}/> */}
        <button className='btn btn-primary' onClick={() => makeInquiry(2,'Ahsan Plaza')}>New Unit Report</button>
        </div>
        <div className='table-responsive px-1'>
         <table id="eduapp" className="table table-bordered bg-white mb-2 ">
            <thead >
                <tr className='table-primary'>
                {headers.map(head => (
                <th id={head.dataKey} className={head.align} key={head.key}>
                {head.title}</th> ))}
                </tr>
            </thead>
            <tbody>
            {reportP2.map((value, index) =>
                <tr key={value.id}>
                <td className='text-center'>{value.name}</td>
                <td className='text-center w-auto'>
                <div className='d-flex'>
                    <input className={`form-control mb-0 me-2 ${value.calculate ? 'w-auto' : 'w-100'}`} value={value.total_unit || ''}
                    onChange={(e) => handleTotalUnitChange(index, e.target.value, value.previous_unit, 2)} />
                    {value.calculate && <button className='btn btn-info w-auto' 
                    onClick={() => calculateUnit(index, value, 2)}>Calculate</button>}
                </div>
                </td>
                <td className='text-center'>
                    <input type='number' className='form-control mb-0' value={value.previous_unit || ''}
                    onChange={(e) => handlePreviousUnitChange(index, e.target.value, 2)} />
               </td>
                <td className='text-center'>{value.unit_rate}</td>
                <td className='text-center'>{value.present_unit}</td>
                <td className='text-center'>{value.unit_amount}</td>
                <td className='text-center'>{value.motor_bill}</td>
                <td className='text-center'>{value.previous_balance}</td>
                <td className='text-center'>{value.total_amount}</td>
                <td className='text-center'> 
                    <input type='number' className='form-control mb-0' value={value.paid_amount || ''}
                    onChange={(e) => handlePaidChange(index, e.target.value, 2)} />

                </td>
                <td className='text-center'>{(value.newpaid === true || !value.paid_date)?
                    <input type='date' className='form-control mb-0' value={value.paid_date || ''}
                    onChange={(e) => handlePaidDateChange(index, e.target.value, 2)} />
                    :
                    moment(value.paid_date).format('D MMMM YYYY')}
                </td>
                <td className='text-center'>{value.remaining_amount}</td>
                </tr>
            )}
            <tr>
                <td className='bold text-center size-18'>Motor</td>
                <td className='text-center size-18 '>{motorP2 && motorP2.previous_unit}</td>
                <td className='text-center size-18'>{motorP2 && motorP2.unit_rate}</td>
                <td className='text-center size-18'>{motorP2 && motorP2.total_unit}</td>
                <td className='text-center size-18 '>{motorP2 && motorP2.unit}</td>
                <td className='text-center size-18 '>{motorP2 && motorP2.unit_amount}</td>
                <td className='text-center bold'>{motorP2 && motorP2.division}</td>
                <td className=''></td>
                <td className=''></td>
                <td className=''></td>
                <td className=''></td>
            </tr>
            </tbody>
         </table>
        </div>
        <div className='d-flex p-2 mb-5'>
            <button className='btn btn-success px-5 py-2' onClick={() => submitReport(2)}>Save Changes</button>
         </div>
        </div>
        {/* Ikram Plaza */}
        <div className='my-5 border shadow bg-white'>
        <h4 className='text-center acc-label mb-0 py-2 border p-bg'>Ikram Plaza {monthP3 ? '['+ moment(monthP3).format('D MMMM YYYY')+']' : ''}</h4>
        <div className='d-flex my-2 justify-content-end px-1'>
        {/* <ExcelExport xlsxfilename={'UnitReport'} header="UnitReport" data={reportP3}  plaza={'Ikram Plaza'} date={monthP3} /> */}
        <button className='btn btn-primary' onClick={() => createUnitReport(3, 'Ikram Plaza')}>New Unit Report</button>
        </div>
        <div className='table-responsive px-1'>
         <table id="eduapp" className="table table-bordered bg-white mb-2 ">
            <thead >
                <tr className='table-primary'>
                {headersP3.map(head => (
                <th id={head.dataKey} className={head.align} key={head.key}>
                {head.title}</th> ))}
                </tr>
            </thead>
            <tbody>
            {reportP3.map((value, index) =>
                <tr key={value.id}>
                <td className='text-center'>{value.name}</td>
                  <td className='text-center w-auto'>
                <div className='d-flex'>
                    <input className={`form-control mb-0 me-2 ${value.calculate ? 'w-auto' : 'w-100'}`} value={value.total_unit || ''}
                    onChange={(e) => handleTotalUnitChange(index, e.target.value, value.previous_unit, 3)} />
                    {value.calculate && <button className='btn btn-info w-auto' 
                    onClick={() => calculateUnit(index, value, 3)}>Calculate</button>}
                </div>
                </td>
                <td className='text-center'>
                    <input type='number' className='form-control mb-0' value={value.previous_unit || ''}
                    onChange={(e) => handlePreviousUnitChange(index, e.target.value, 3)} />
               </td>
                <td className='text-center'>{value.unit_rate}</td>
                <td className='text-center'>{value.present_unit}</td>
                <td className='text-center'>{value.unit_amount}</td>
                <td className='text-center'>{value.previous_balance}</td>
                <td className='text-center'>{value.total_amount}</td>
                <td className='text-center'> 
                    <input type='number' className='form-control mb-0' value={value.paid_amount || ''}
                    onChange={(e) => handlePaidChange(index, e.target.value, 3)} />

                </td>
                <td className='text-center'>{(value.newpaid === true || !value.paid_date)?
                    <input type='date' className='form-control mb-0' value={value.paid_date || ''}
                    onChange={(e) => handlePaidDateChange(index, e.target.value, 3)} />
                    :
                    moment(value.paid_date).format('D MMMM YYYY')}
                </td>
                <td className='text-center'>{value.remaining_amount}</td>
                </tr>
            )}
            </tbody>
         </table>
        </div>
        <div className='d-flex p-2 mb-5'>
            <button className='btn btn-success px-5 py-2' onClick={() => submitReport(3)}>Save Changes</button>
         </div>
        </div>

        </div>
        <InquiryModel
        show={newInquiry}
        type={type}
        unitRate={unitRate}
        previousUnit={previousUnit}
        existingMonths={existingMonths}
        pid={pid}
        parentCallback={handleModelCallback}
        />
        <DateModel 
        show={showModel}
        type={type}
        pid={pid}
        parentCallback={handleDateModelCallback} />

        </>
    )
}
export default UnitReport;