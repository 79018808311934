import React, { useEffect, useRef, useState } from 'react';
import InquiryModel from '../Plazas/inquiryModel';
import swal from 'sweetalert';
import { getRentReport } from '../../services/apiServices';
import moment from 'moment';
import ExcelExport from '../excelExport';
import RentDateModel from '../Plazas/rentDateModel';
import MultiTableExcelExport from '../exportAllTables';
import { Link } from "react-router-dom";

const RentReport = () => {
    const[newInquiry, setNewInquiry] = useState(false);
    const[showModel, setShowModel] = useState(false);
    const[type, setType] = useState(false);
    const[pid, setPid] = useState('');
    const[reportP1, setReportP1] = useState([]);
    const[reportP2, setReportP2] = useState([]);
    const[reportP3, setReportP3] = useState([]);
    const[month, setMonth] = useState('');
    const hasFetched = useRef(false);
    const [headers] = useState([ 
        {title: 'FlatNo' , dataKey: "name",key:1, align: "text-center"},
        {title: 'Advance' , dataKey: "advance", align: "text-center",key:5},
        {title: 'Lease Date' , dataKey: "rent_out_date", align: "text-center",key:2},
        {title: 'Rent' , dataKey: "rent", align: "text-center",key:4},
        {title: 'Prev Bal' , dataKey: "previous_rent", align: "text-center",key:6},
        {title: 'Sweeper' , dataKey: "sweeper", align: "text-center",key:7},
        {title: 'Total' , dataKey: "total_rent_amount", align: "text-center",key:8},
        {title: 'Received' , dataKey: "paid_rent", align: "text-center",key:9},
        {title: 'Remaining' , dataKey: "remaining_rent", align: "text-center",key:10},
        {title: 'Date' , dataKey: "paid_date", align: "text-center",key:11},
    ]);

    useEffect(() => {
        if (hasFetched.current) return;
        hasFetched.current = true;
        fetchReport();
    }, []);

    const fetchReport = async () => {
        let result = await getRentReport();
        console.log('report',result.data)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            if(result.data.data){
                setReportP1(result.data.data['P1']);
                setReportP2(result.data.data['P2']);
                setReportP3(result.data.data['P3']);
                setMonth(result.data.data['month'])
            }
        }
    }

    const handleModelCallback = (type) => {
        if(type === 'cancel'){
            setNewInquiry(false);
            setType('');
        } else if('created'){
            setNewInquiry(false);
            fetchReport();
        }
    }
    const submitReport= () => {

    }

    const handleDateModelCallback = (type) => {
        if(type === 'cancel'){
            setShowModel(false);
            setType('');
        } else if('created'){
            setShowModel(false);
            fetchReport();
        }
    }
    const handlePaidDateChange = (index, value, from) => {
        if(from === 1){
            const updatedReport = [...reportP1];
            updatedReport[index].paid_date = value;
            setReportP1(updatedReport);
        } else if(from === 2){
            const updatedReport = [...reportP2];
            updatedReport[index].paid_date = value;
            setReportP2(updatedReport);
        } else if(from === 3){
            const updatedReport = [...reportP3];
            updatedReport[index].paid_date = value;
            setReportP3(updatedReport);
        }
    }
    const handlePaidChange = (index, value, from) => {
        if(from === 1){
            const updatedReport = [...reportP1];
            if(value < 0){
                swal({ text: 'Enter Amount greater than 0', icon: "warning", dangerMode: true });
                return false;
            } else{
                updatedReport[index].paid_rent = value;
                updatedReport[index].newpaid = true;
                let amount = updatedReport[index].total_rent_amount-updatedReport[index].paid_rent
                updatedReport[index].remaining_rent = amount;
                setReportP1(updatedReport);
            } 
        } else if(from === 2){
                const updatedReport = [...reportP2];
                if(value < 0){
                    swal({ text: 'Enter Amount greater than 0', icon: "warning", dangerMode: true });
                    return false;
                } else{
                    updatedReport[index].paid_rent = value;
                    updatedReport[index].newpaid = true;
                    let amount = updatedReport[index].total_rent_amount-updatedReport[index].paid_rent
                    updatedReport[index].remaining_rent = amount;
                    setReportP2(updatedReport);
                }
        } else if(from === 3){
            const updatedReport = [...reportP3];
            if(value < 0){
                swal({ text: 'Enter Amount greater than 0', icon: "warning", dangerMode: true });
                return false;
            } else{
                updatedReport[index].paid_rent = value;
                updatedReport[index].newpaid = true;
                let amount = updatedReport[index].total_rent_amount-updatedReport[index].paid_rent
                updatedReport[index].remaining_rent = amount;
                setReportP2(updatedReport);
            }
    }
    }
  
    return(
        <>
        <div className='px-lg-4 px-2 mb-5'>
        <div className='justify-content-between d-flex mt-3'>
        <MultiTableExcelExport 
        xlsxfilename={'UnitReport'}
        data1={reportP1}
        data2={reportP2}
        data3={reportP3} 
        date={month} 
        title={'UnitReport'} 
        />
        <button className='btn btn-secondary px-4 py-2' onClick={() => setShowModel(true)} >New Monthly Report</button>
        </div>
        
        {/* Ahmad Plaza */}
        <div className='mb-5 mt-3 border shadow bg-white'>
        <h4 className='text-center acc-label mb-0 py-2 border p-bg'>Ahmad Plaza {month ? '['+ moment(month).format('D MMMM YYYY')+']' : ''}</h4>
        {/* <div className='d-flex my-2 justify-content-between px-1'>
        <ExcelExport xlsxfilename={'UnitReport'} header="UnitReport" data={reportP1} plaza={'Ahmad Plaza'} date={month} />
        </div> */}
        <div className='table-responsive px-1'>
         <table id="eduapp" className="table table-bordered bg-white mb-2 ">
            <thead >
                <tr className='table-primary'>
                {headers.map(head => (
                <th id={head.dataKey} className={head.align} key={head.key}>
                {head.title}</th> ))}
                </tr>
            </thead>
            <tbody>
            {reportP1.map((value, index) =>
                <tr key={value.id}>
                <td className='text-center'>{value.name}</td>
                <td className='text-center w-auto'>{value.advance}</td>
                <td className='text-center'>{value.rent_out_date} </td>
                <td className='text-center'>{value.rent}</td>
                <td className='text-center'>{value.previous_rent}</td>
                <td className='text-center'>{value.sweeper}</td>
                <td className='text-center'>{value.total_rent_amount}</td>
                <td className='text-center'> 
                    <input type='number' className='form-control mb-0' value={value.paid_rent || ''}
                    onChange={(e) => handlePaidChange(index, e.target.value, 1)} />

                </td>
                <td className='text-center'>{value.remaining_rent}</td>
                <td className='text-center'>{(value.newpaid === true || !value.paid_date)?
                    <input type='date' className='form-control mb-0' value={value.paid_date || ''}
                    onChange={(e) => handlePaidDateChange(index, e.target.value, 1)} />
                    :
                    moment(value.paid_date).format('D MMMM YYYY')}
                </td>
                </tr>
            )}
            </tbody>
         </table>
         
        </div>
        <div className='d-flex p-2 mb-5'>
            <button className='btn btn-success px-5 py-2' onClick={() => submitReport(1)}>Save Changes</button>
         </div>
        </div>
        {/* Ahsan Plaza */}
        <div className='my-5 border shadow bg-white'>
        <h4 className='text-center acc-label mb-0 py-2 border p-bg'>Ahsan Plaza {month ? '['+ moment(month).format('D MMMM YYYY')+']' : ''}</h4>
     
        <div className='table-responsive px-1'>
         <table id="eduapp" className="table table-bordered bg-white mb-2 ">
            <thead >
                <tr className='table-primary'>
                {headers.map(head => (
                <th id={head.dataKey} className={head.align} key={head.key}>
                {head.title}</th> ))}
                </tr>
            </thead>
            <tbody>
            {reportP2.map((value, index) =>
                <tr key={value.id}>
                <td className='text-center'>{value.name}</td>
                <td className='text-center w-auto'>{value.advance}</td>
                <td className='text-center'>{value.rent_out_date} </td>
                <td className='text-center'>{value.rent}</td>
                <td className='text-center'>{value.previous_rent}</td>
                <td className='text-center'>{value.sweeper}</td>
                <td className='text-center'>{value.total_rent_amount}</td>
                <td className='text-center'> 
                    <input type='number' className='form-control mb-0' value={value.paid_rent || ''}
                    onChange={(e) => handlePaidChange(index, e.target.value, 2)} />

                </td>
                <td className='text-center'>{value.remaining_rent}</td>
                <td className='text-center'>{(value.newpaid === true || !value.paid_date)?
                    <input type='date' className='form-control mb-0' value={value.paid_date || ''}
                    onChange={(e) => handlePaidDateChange(index, e.target.value, 2)} />
                    :
                    moment(value.paid_date).format('D MMMM YYYY')}
                </td>
                </tr>
            )}
            </tbody>
         </table>
        </div>
        <div className='d-flex p-2 mb-5'>
            <button className='btn btn-success px-5 py-2' onClick={() => submitReport(2)}>Save Changes</button>
         </div>
        </div>
        {/* Ikram Plaza */}
        <div className='my-5 border shadow bg-white'>
        <h4 className='text-center acc-label mb-0 py-2 border p-bg'>Ikram Plaza {month ? '['+ moment(month).format('D MMMM YYYY')+']' : ''}</h4>
       
        <div className='table-responsive px-1'>
         <table id="eduapp" className="table table-bordered bg-white mb-2 ">
            <thead >
                <tr className='table-primary'>
                {headers.map(head => (
                <th id={head.dataKey} className={head.align} key={head.key}>
                {head.title}</th> ))}
                </tr>
            </thead>
            <tbody>
            {reportP3.map((value, index) =>
                <tr key={value.id}>
                <td className='text-center'>{value.name}</td>
                <td className='text-center w-auto'>{value.advance}</td>
                <td className='text-center'>{value.rent_out_date} </td>
                <td className='text-center'>{value.rent}</td>
                <td className='text-center'>{value.previous_rent}</td>
                <td className='text-center'>{value.sweeper}</td>
                <td className='text-center'>{value.total_rent_amount}</td>
                <td className='text-center'> 
                    <input type='number' className='form-control mb-0' value={value.paid_rent || ''}
                    onChange={(e) => handlePaidChange(index, e.target.value, 3)} />

                </td>
                <td className='text-center'>{value.remaining_rent}</td>
                <td className='text-center'>{(value.newpaid === true || !value.paid_date)?
                    <input type='date' className='form-control mb-0' value={value.paid_date || ''}
                    onChange={(e) => handlePaidDateChange(index, e.target.value, 3)} />
                    :
                    moment(value.paid_date).format('D MMMM YYYY')}
                </td>
                </tr>
            )}
            </tbody>
         </table>
        </div>
        <div className='d-flex p-2 mb-5'>
            <button className='btn btn-success px-5 py-2' onClick={() => submitReport(3)}>Save Changes</button>
         </div>
        </div>

        </div>
         <RentDateModel
         show={showModel}
         parentCallback={handleDateModelCallback} />
        
        </>
    )
}
export default RentReport;