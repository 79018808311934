
import React, { useEffect, useRef, useState } from 'react';
import Title from '../Title';
import '../../styles/global.css'
import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import { getFlats } from '../../services/apiServices';
import { Sorting } from '../SortTables';
import { Link } from "react-router-dom";

const ViewFlats = () => {
    const { pid } = useParams();
    const [allFlats, setAllFlats] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [loading, setLoading] = useState("ASC");
    const hasFetched = useRef(false);

    const [headers] = useState([ 
        {title: '#' , dataKey: "index", align: "text-center", key:1},
        {title: 'FlatNo' , dataKey: "name",key:2, align: "text-center"},
        {title: 'Rent', dataKey: "rent", align: "text-center",key:3},
        {title: 'Sweeper' , dataKey: "sweeper_charges", align: "text-center",key:4},
        {title: 'Unit_Rate' , dataKey: "rate_per_unit", align: "text-center",key:5},
        {title: 'Advance' , dataKey: "advance_payment", align: "text-center",key:6},
        {title: 'Action' , dataKey: "rate_per_unit", align: "text-center",key:7},
        {title: 'Status' , dataKey: "status", align: "text-center",key:8},
    ]);

    useEffect(() => {
        console.log(pid)
        // if (hasFetched.current) return;
        // hasFetched.current = true;
        fetchFlats();
    }, [pid]);

    const fetchFlats = async () => {
        setLoading(true);
        let result = await getFlats({pid, currentPage: 1, perPage: 35});
        setLoading(false);
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            console.log(result.data)
            if(result.data.data){
                setAllFlats(result.data.data['data']);
            }
        }
    }
    const sorting = (col) => {
        const sortResult = Sorting(allFlats, col, order)
        setOrder(sortResult[0]);
        setAllFlats(sortResult[1]);
    }

    return (
        <>
        <div className='px-lg-4 px-2 mb-5'>

        <Title title={"Ahmad Plaza"} back={true} btn={true}  btnText="ADD FLAT" 
         btnClass="float-right btn btn-info white px-4 py-2"
         btnUrl={`/add-flat/${pid}`}
         btnCol={`col-lg-8 col-7 text-right my-2`} />
         {loading ? '...' :
         <div className='table-responsive'>
         <table className="table table-bordered bg-white mb-2 nowrap">
         <thead >
         <tr className='table-primary'>
         {headers.map(head => (
         <th id={head.dataKey} className={head.align} onClick={() => sorting(head.dataKey)} key={head.key}>
         {head.title}</th>
         ))}
         </tr>
         </thead>
         <tbody>
         {allFlats.map((value, index) =>
            <tr key={value.id}>
            <td className='text-center'>{++index}</td>
            <td className='text-center'>{value.name}</td>
            <td className='text-center'>{value.rent}</td>
            <td className='text-center'>{value.sweeper_charges}</td>
            <td className='text-center'>{value.rate_per_unit}</td>
            <td className='text-center'>{value.advance_payment}</td>
            <td className='text-center w-25'>
            <div className='d-flex justify-content-center'>
            <Link to={`/flat-status/${value.id}`}>
            <button className='btn btn-outline-primary'>View Status</button></Link>
            <Link to={`/update-flat/${value.id}`}>
            <button className='btn btn-outline-success mx-2'>Update Flat</button></Link></div></td>
            {value.status === 0 ? <td className='text-center' style={{color:'red'}}>Deactivate</td>
            : <td className='text-center' style={{color:'green'}}>Active</td>}
            </tr>
         )}
         </tbody>
         </table>
         </div>}
        </div>
        </>
        )
}
export default  ViewFlats