
import React, { useEffect, useRef, useState } from 'react';
import Title from '../Title';
import '../../styles/global.css'
import swal from 'sweetalert';
import { useNavigate, useParams } from "react-router-dom";
import {editFlat, flatDetail , flatStatus} from '../../services/apiServices';

const EditFlat = () => {
    const navigate = useNavigate();
    const { flatid } = useParams();
    const [name, setName] = useState('');
    const [rent, setRent] = useState('');
    const [advancePayment, setAdvancePayment] = useState('');
    const [sweeperCharges, setSweeperCharges] = useState('');
    const [ratePerUnit, setRatePerUnit] = useState('');
    const [rentDate, setRentDate] = useState('');
    const [refund ,setRefund] = useState('');
    const [notes  ,setNotes] = useState('');
    const [nameError, setNameError] = useState('');
    const [fixedMotor, setFixedMotor] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const hasFetched = useRef(false);

    useEffect(() => {
        if (hasFetched.current) return;
        hasFetched.current = true;
        getFlatDetatil()
    }, []);

    const getFlatDetatil = async () => {
        let result = await flatDetail(flatid);
        console.log(result.data)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            const data = result.data.data
            setName(data.name);
            setRent(data.rent)
            setAdvancePayment(data.advance_payment)
            setSweeperCharges(data.sweeper_charges)
            setRefund(data.refund)
            setRatePerUnit(data.rate_per_unit)
            setRentDate(data.rent_out_date)
            setFixedMotor(data.motor_bill_fixed)
            setNotes(data.notes)
            setActiveStatus(data.status)
        }
    }

    const handleSubmit = async () => {
        if(!name){
            setNameError('Please enter name')
            return false
          } else{
            const data = {
                name:name,
                flatid: flatid,
                rent: rent,
                advancePayment: advancePayment,
                sweeperCharges: sweeperCharges,
                ratePerUnit: ratePerUnit,
                refund: refund,
                rentDate: rentDate,
                fixedMotor: fixedMotor
            }
            console.log(data);
            let result = await editFlat( {data});
            if (result.error) {
                swal({ text: result.error, icon: "warning", dangerMode: true });
            } else {
                swal("Success!", result.message, "success");
                navigate(-1)
            }
          }
    }
    const activateFlat = async () => {
        let result = await flatStatus(flatid, 1);
        console.log(result.data)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            getFlatDetatil()
        }
    }
    const deActivateFlat = async () => {
        let result = await flatStatus(flatid, 0);
        console.log(result.data)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            getFlatDetatil()
        }
    }

    return (
        <>
        <div className='px-lg-4 px-2 mb-5'>
        <Title back={true}/>
        <div className=' py-lg-0 bg-white border shadow'>
        <h5 className='acc-label white text-center s-bg mb-0 py-2'>Update New Flat</h5>
        <div className='row px-3 mt-2 px-lg-5 px-3 py-lg-2'>
        <div className="form-group col-lg-4 col-12">
            <label className="font-f-roboto size-18 mb-0">Flat/Shop No</label>
            <input type="text" value={name} className="form-control acc-form w-90 mb-3 mb-lg-4 " onChange={(e) => setName(e.target.value)} 
            placeholder="Please Enter" />
             {nameError && <><small className="error">{nameError}</small></>}
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Total Rent</label>
            <input type="number" value={rent} className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setRent(e.target.value)} 
            placeholder="Please Enter" />
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Advance Payment</label>
            <input type="number" value={advancePayment} className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setAdvancePayment(e.target.value)} 
            placeholder="Please Enter" />
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Sweeper Charges</label>
            <input type="number" value={sweeperCharges} className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setSweeperCharges(e.target.value)} 
            placeholder="Please Enter" />
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Rate Per Unit</label>
            <input type="number" value={ratePerUnit} className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setRatePerUnit(e.target.value)} 
            placeholder="Please Enter" />
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Refund</label>
            <input type="number" value={refund} className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setRefund(e.target.value)} disabled />
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Date of Lease</label>
            <input  type="text" name="rentdate" className="date-input w-90 mb-2 mb-lg-4"
                onFocus={(e) => e.target.type = 'date'}
                onBlur={(e) => {
                    if (!e.target.value) e.target.type = 'text';
                }}
                onChange={(e) => setRentDate(e.target.value)} placeholder="Please Enter Date"
                value={rentDate} />
        </div>

        <div className="form-group col-lg-4 col-6">
            <label className="font-f-roboto  size-18 mb-0">Fixed Motor Bill</label>
            <input type="number" value={fixedMotor} className="form-control acc-form w-90 mb-3 mb-lg-4" onChange={(e) => setFixedMotor(e.target.value)} />
        </div>

        <div className="form-group col-lg-6 col-12 mb-3">
            <label className="font-f-roboto  size-18 mb-0">Notes</label>
            <textarea className="textarea form-control acc-form" value={notes} onChange={(e) => setNotes(e.target.value)} 
           placeholder="Please Enter" rows="4" cols="60"></textarea>
        </div>

        <div className='d-flex'>
        {(activeStatus === 0 || activeStatus === '0') ?
            <button className='btn btn-info my-4 mt-0 px-3 py-2' onClick={() => activateFlat()}>Activate Flat</button>
            :
            <button className='btn btn-danger my-4 px-3 py-2' onClick={() => deActivateFlat()}>Deactivate Flat</button>
        }
        </div>
            
        <div className='d-flex'>
                <button className='btn btn-success my-4 mt-0 px-5 w-100' onClick={() => handleSubmit()}>Update Flat</button>
            </div>
        </div>

        </div>
        </div>
        </>
        )
}
export default  EditFlat