import React, { useEffect, useRef, useState } from 'react';
import Title from '../Title';
import '../../styles/global.css'
import swal from 'sweetalert';
import { useNavigate, useParams } from "react-router-dom";
import { flatDetail } from '../../services/apiServices';

const FlatStatus = () => {
    const navigate = useNavigate();
    const { flatid } = useParams();
    const [detail, setDetail] = useState([]);
    const hasFetched = useRef(false);

    useEffect(() => {
        if (hasFetched.current) return;
        hasFetched.current = true;
        getFlatDetatil()
    }, []);

    const getFlatDetatil = async () => {
        let result = await flatDetail(flatid);
        console.log(result.data)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            setDetail(result.data.data);
        }
    }
    return (
        <>
        <div className='px-lg-4 px-2 mb-5'>
        <Title back={true}/>
        <div className='mx-lg-3 bg-white border m-1'>
        <h4 className='text-center pt-3'>Flat Detail</h4>
        <div className='row justify-content-center font-f-roboto mb-5'>
        <div className="table-responsive p-2">
         <table className="table table-bordered-bottom table-hover mb-0">
         <tbody>
         <tr className='table-info'>
         <td className='text-lg-center'>Flat/Show No: <strong>&nbsp;{detail['name']}</strong></td>
         <td className='text-lg-center'>Total Rent: <strong>&nbsp;{detail['rent']}</strong></td>
         </tr>
         <tr>
         <td className='text-lg-center'>Advance Payment:<strong>&nbsp;{detail['advance_payment']}</strong></td>
         <td className='text-lg-center'>Advance Received:<strong>&nbsp;{detail['refund']}</strong></td>
         </tr>
         <tr className='table-info'>
         <td className='text-lg-center'>Sweeper Charges:<strong>&nbsp;{detail['sweeper_charges']}</strong></td>
         <td className='text-lg-center'>Rate Per Unit:<strong>&nbsp;{detail['rate_per_unit']}</strong></td>
         </tr>
         <tr>
         </tr>
         </tbody>
         </table>
         </div>
        </div>
        
        </div>
        </div>
        </>
    )
}
export default  FlatStatus