import React, { useRef } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment'; // For date formatting
import html2canvas from 'html2canvas';

const ExportPDFReport = ({ data = [], headers = [], urduText }) => {
  const urduTextRef = useRef(null);

  const downloadPdf = async () => {
    const doc = new jsPDF('p', 'pt', 'a4'); // A4 size PDF
    const marginLeft = 40; // Left margin
    const marginTop = 40; // Top margin
    const pageWidth = doc.internal.pageSize.width; // Get page width

    // Map headers to titles and find indexes of 'Total' and 'total_unit' columns
    const headerTitles = headers.map((head) => head.title);
    const styledColumnIndexes = headerTitles.reduce((indexes, title, index) => {
      const lowerTitle = title.toLowerCase();
      if (lowerTitle === 'total' || lowerTitle === 'total_unit') {
        indexes.push(index);
      }
      return indexes;
    }, []);

    let y = marginTop; // Initial Y position for content
    let tableCount = 0; // Track number of tables on the page

    // Ensure data and headers are available
    if (!data.length || !headers.length) {
      console.error('No data or headers available for export');
      return;
    }

    // **Render the Urdu text as an image**
    let urduImageData = null;
    let imgWidth = 500; // Adjust as needed
    let imgHeight;
    try {
      const element = urduTextRef.current;
      const scale = 3; // Increase the scale for higher resolution

      // Use html2canvas with increased scale
      const canvas = await html2canvas(element, {
        useCORS: true,
        scale: scale,
      });

      urduImageData = canvas.toDataURL('image/png');
      imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
    } catch (error) {
      console.error('Error generating Urdu text image:', error);
    }

    // Loop through the data to generate tables
    data.forEach((v) => {
      // Add section header for each table
      const plazaName =
        v.pid === 1
          ? 'Ahmad Plaza'
          : v.pid === 2
          ? 'Ahsan Plaza'
          : v.pid === 3
          ? 'Ikram Plaza'
          : '';
      let flatName = v.name;
      if (flatName.includes('Faizan(S2)')) {
        flatName = flatName.replace('(S2)', '(2)'); // Replace "(S2)" with "(2)"
      }
      if (flatName.includes('Saloon(S1)')) {
        flatName = flatName.replace('(S1)', '(1)'); // Replace "(S1)" with "(1)"
      }
      if (flatName.includes('Qamar(S3)')) {
        flatName = flatName.replace('(S3)', '(3)'); // Replace "(S3)" with "(3)"
      }
      const flatInfo = `FLAT NO: ${flatName}`;
      const reportDate = v.motor_report
        ? moment(v.motor_report.month).format('D MMMM YYYY')
        : moment(v.created_at).format('D MMMM YYYY');

      // Set font and text color for the header row
      const headerFontSize = 16;
      doc.setFontSize(headerFontSize);
      doc.setTextColor('#000000'); // Black text color

      // Calculate text height
      const textHeight = doc.getTextDimensions(flatInfo).h;
      const rectHeight = textHeight + 7; // Add some padding
      const rectWidth = pageWidth - 2 * marginLeft; // Width of the rectangle
      const rectX = marginLeft; // X position
      const rectY = y - textHeight + 0; // Adjust Y position to cover the text

      // Set fill color to grey and draw the rectangle
      doc.setFillColor('#D3D3D3'); // Light grey background
      doc.rect(rectX, rectY, rectWidth, rectHeight, 'F'); // 'F' means filled rectangle

      // Add the texts over the rectangle
      doc.text(flatInfo, marginLeft, y);
      const plazaNameX = pageWidth / 2; // Centered horizontally
      doc.text(plazaName, plazaNameX, y, { align: 'center' });
      const reportDateX = doc.internal.pageSize.width - marginLeft; // Right-aligned Report Date
      doc.text(reportDate, reportDateX, y, { align: 'right' });

      y += rectHeight - (textHeight - 0); // Move Y position to just below the rectangle

      // Prepare table body from the report data
      const existingTableBody = v.report.map((value) => [
        moment(value.month).format('MMM'),
        value.total_unit,
        value.previous_unit,
        value.present_unit,
        value.unit_rate,
        value.unit_amount,
        value.motor_bill,
        value.previous_balance,
        value.total_amount,
      ]);

      // Define months to ensure are included
      const monthsToInclude = ['Oct', 'Nov'];

      // Add empty rows for months not in existing data
      monthsToInclude.forEach((monthAbbrev) => {
        const monthExists = existingTableBody.some(
          (row) => row[0] === monthAbbrev
        );
        if (!monthExists) {
          existingTableBody.push([monthAbbrev, '', '', '', '', '', '', '', '']);
        }
      });

      // Sort the table body by month (optional)
      const monthOrder = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12,
      };

      existingTableBody.sort((a, b) => {
        return monthOrder[a[0]] - monthOrder[b[0]];
      });

      const tableBody = existingTableBody;

      // Add motor report data if available
      if (v.motor_report) {
        tableBody.push([
          'Motor Bill',
          v.motor_report.total_unit,
          v.motor_report.previous_unit,
          v.motor_report.unit,
          v.motor_report.unit_rate,
          v.motor_report.unit_amount,
          v.motor_report.division,
          '', // Empty cells
          '',
        ]);
      }

      // Render the table with autoTable
      doc.autoTable({
        startY: y, // Start at the current Y position
        head: [headerTitles], // Dynamic headers
        body: tableBody, // Dynamic table body
        margin: { left: marginLeft },
        styles: {
          fontSize: 11,
          textColor: '#000000',
          lineWidth: 0.1, // Add table borders
          lineColor: '#000000', // Border color
        },
        headStyles: {
          fontSize: 11,
          textColor: '#000000',
          fillColor: '#FFFFFF', // White background for headers
        },
        bodyStyles: {
          fontSize: 10.5,
          textColor: '#000000',
          fillColor: '#FFFFFF', // White background for body cells
          halign: 'center', // Center align text in body cells
        },
        columnStyles: styledColumnIndexes.reduce((styles, index) => {
          styles[index] = {
            fillColor: '#333333',
            textColor: '#FFFFFF', // White text
            fontStyle: 'bold', // Bold text
            lineColor: '#FFFFFF',
          };
          return styles;
        }, {}),
        didParseCell: function (data) {
          // Make 'Motor Bill' row bold
          if (
            data.section === 'body' &&
            data.row.raw[0] === 'Motor Bill'
          ) {
            data.cell.styles.fontStyle = 'bold';
          }
          // Style header cells of special columns
          if (
            data.section === 'head' &&
            styledColumnIndexes.includes(data.column.index)
          ) {
            data.cell.styles.fillColor = '#000000'; // Black background for styled header cells
            data.cell.styles.textColor = '#FFFFFF'; // White text
            data.cell.styles.fontStyle = 'bold'; // Bold text
          }
        },
      });

      y = doc.lastAutoTable.finalY + 1; // Reduce the distance here

      // **Include the Urdu text image at the bottom of each table**
      if (urduImageData) {
        doc.addImage(urduImageData, 'PNG', marginLeft, y, imgWidth, imgHeight);
        y += imgHeight + 40; // Reduce distance between Urdu text and next content
      }
      

      // Check if we need a new page after 3 tables
      tableCount++;
      if (tableCount >= 3) {
        doc.addPage(); // Add new page
        y = marginTop; // Reset Y position for new page
        tableCount = 0; // Reset table count
      }
    });

    // Save the generated PDF
    doc.save('report_tables.pdf');
  };

  return (
    <div>
      {/* Hidden div with Urdu text */}
      <div
        ref={urduTextRef}
        style={{
          fontSize: '16px', // Increase font size for better clarity
          fontFamily: 'JameelNooriNastaleeq, Arial, sans-serif',
          direction: 'rtl',
          textAlign: 'right',
          width: '500px',
          position: 'absolute',
          top: '-9999px', // Hide the element off-screen
        }}
      >
       {urduText}
      </div>
      <button className='btn btn-primary ms-3' onClick={downloadPdf}>Export To PDF</button>
    </div>
  );
};

export default ExportPDFReport;
