import React, { useState } from 'react';
import { loginUser } from '../../services/apiServices';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import '../../styles/global.css';
import { setUserSession } from '../../utilis/common';

const UserForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [nameError, setNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

   function handleReset() {
    setName(''); setPassword(''); setNameError(null); setPasswordError(null);
   } 
   const handleValidation = () => {
    handleReset();
    if(!name){
      setNameError('Please enter name')
      return false
    } else if(!password){
      setPasswordError('Please enter password')
      return false  
    } else{
      return true
    }
  }
   const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (handleValidation()) {
      const result = await loginUser( {name, password});
      if (result.error) {
        swal({ text: result.error, icon: "warning", dangerMode: true });
        setLoading(false)
      } else {
        setLoading(false)
        setUserSession(result.data.token, result.data.user);
        if(localStorage.getItem('token')){
          navigate('/dashboard');
          window.location.reload(false);
        }
      }
    }
  }

  return (
    <div className='user-page h-100'>
    <div className="row w-100">
    <div className='container p-5'>
    <div className="form-box">
      <h2>Login User</h2>
      <form className='my-4'>
        <input type="text" value={name} placeholder="Username" required onChange={(e) => setName(e.target.value)} />
        {nameError && <><small className="error">{nameError}</small></>}
        <input type={showPassword ? "text" : "password"} value={password} placeholder="Password" required onChange={(e) => setPassword(e.target.value)} />
        {passwordError && <><small className="error">{passwordError}</small></>}
        <div className='mt-2'>
          <label className='text-white size-18'>
          <input type="checkbox" checked={showPassword} onChange={(e) => setShowPassword(e.target.checked)} />
          Show Password
          </label>
        </div>
        {loading ? '...' : 
        <div className="button-group mt-3">
          <button type="text" className="reset-btn w-100" onClick={handleSubmit}>Login</button>
        </div>}
      </form>
    </div>
    </div>
  </div>
  </div>
  );
};

export default UserForm;
