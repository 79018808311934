import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

const ExportMonthlyReport = ({ xlsxfilename, data, urduText }) => {
    const handleExport = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Monthly Report');
            worksheet.pageSetup = {
                paperSize: 9, // A4 size
                orientation: 'portrait',
                fitToPage: true, // Fit to page
                fitToWidth: 1, // Fit to 1 page width
                fitToHeight: 0, // Allow unlimited height
                margins: {
                    left: 0.7, right: 0.7, top: 0.75, bottom: 0.75,
                    header: 0.3, footer: 0.3
                }
            };

            // Set the starting row for each table
            let startRow = 1;

            data.forEach((report, index) => {
                // Add the report name and date as a header for each flat
                const titleRow = worksheet.getRow(startRow);
                // titleRow.height = 42; 
                const plazaName = report.pid === 1 ? 'Ahmad Plaza' : report.pid === 2 ? 'Ahsan Plaza' : report.pid === 3 ? 'Ikram Plaza' : '';
                let flatName = report.name;
                if (flatName.includes('Faizan(S2)')) {
                    flatName = flatName.replace('(S2)', '(2)');  // Remove the "(S1)"
                }
                if (flatName.includes('Saloon(S1)')) {
                    flatName = flatName.replace('(S1)', '(1)');  // Remove the "(S1)"
                }
                if (flatName.includes('Qamar(S3)')) {
                    flatName = flatName.replace('(S3)', '(3)');  // Remove the "(S1)"
                }
                titleRow.getCell(1).value = `FLAT NO. ${flatName}`;
                titleRow.getCell(1).font = { bold: true, size: 15 };
                titleRow.getCell(1).alignment = { horizontal: 'left', vertical: 'middle' };
                titleRow.getCell(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFCCCCCC' }, // Light grey background
                };
                worksheet.mergeCells(startRow, 1, startRow, 2);  // Merge 1st and 2nd columns for Flat No

                // Add Plaza Name in the center (merging cells from 3 to 6)
                titleRow.getCell(3).value = `${plazaName || ''}`;
                titleRow.getCell(3).font = { bold: true, size: 15 };
                worksheet.mergeCells(startRow, 3, startRow, 6);  // Merging cells from 3 to 6 for Plaza Name
                titleRow.getCell(3).alignment = { horizontal: 'center', vertical: 'middle' };
                titleRow.getCell(3).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFCCCCCC' }, // Light grey background
                };

                // Add Date in the last two columns (merge two columns)
                titleRow.getCell(7).value = `Date: ${moment().format('DD-MM-YYYY')}`;
                titleRow.getCell(7).font = { bold: true, size: 15 };
                titleRow.getCell(7).alignment = { horizontal: 'right', vertical: 'middle' };
                titleRow.getCell(7).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFCCCCCC' }, // Light grey background
                };
                worksheet.mergeCells(startRow, 7, startRow, 8);
                startRow += 1;

                // Add the table header for each flat
                const header = ['Month', 'Present Unit', 'Previous Unit', 'Unit', 'Rate',  'Amount', 'Motor Bill', 'Total'];
                worksheet.addRow(header).font = { bold: true };

                // Add the data for the current flat, setting the font size to 12 for each row
                report.report.forEach(item => {
                    const row = worksheet.addRow([
                        moment(item.month).format('MMM'),
                        item.total_unit,           
                        item.previous_unit,              
                        item.present_unit, 
                        item.unit_rate || '',  
                        item.unit_amount || '',          // Amount
                        item.motor_bill || '',           // Motor Bill
                        item.total_amount || ''          // Total
                    ]);
                    // row.height = 24; 
                    // Set font size to 12 for each cell in the row
                    row.eachCell((cell) => {
                        cell.font = { size: 12 };
                    });
                });

                // Add an empty row before the motor report and set next month's name in the first column
                const lastMonth = report.report[report.report.length - 1].month;

                // Loop through the next 3 months and add a row for each
                for (let i = 1; i <= 3; i++) {
                    const nextMonth = moment(lastMonth).add(i, 'months').format('MMM');
                    const emptyRow = worksheet.addRow([nextMonth, '', '', '', '', '', '', '']);
                    emptyRow.getCell(1).font = { size: 12 };
                }

                // If motor report exists, add it to the table
                if (report.motor_report) {
                    const motorRow = worksheet.addRow([
                        'Motor', 
                        report.motor_report.total_unit || '', 
                        report.motor_report.previous_unit || '', 
                        report.motor_report.unit || '', 
                        report.motor_report.unit_rate || '', 
                        report.motor_report.unit_amount || '', 
                        report.motor_report.division || '',
                        ''
                    ]);
                    // motorRow.height = 24; 
                    // Make the entire motor report row bold
                    motorRow.eachCell((cell) => {
                        cell.font = { bold: true, size:12 };
                    });
                }

                const urduTextRow = worksheet.addRow([urduText]);
                // Merge all columns in the row (assuming 8 columns in the table)
                worksheet.mergeCells(urduTextRow.number, 1, urduTextRow.number, 8);
                
                // Set alignment and font on the merged range by targeting the first cell of the merged area
                const mergedCell = worksheet.getCell(urduTextRow.number, 1);  // Use the first cell in the merged range
                
                // Set horizontal and vertical alignment for the merged cell
                mergedCell.alignment = { horizontal: 'right', vertical: 'middle' };  // Align text to the center both horizontally and vertically
                mergedCell.font = { size: 14, bold: true, name: 'Jameel Noori Nastaleeq' };  // Set font to Jameel Noori Nastaleeq
                
                // Apply alignment to all cells in the row, including the merged cells
                urduTextRow.eachCell({ includeEmpty: true }, (cell) => {
                    cell.alignment = { horizontal: 'right', vertical: 'middle' };  // Ensure alignment is applied to all cells
                    cell.border = null;  // Remove borders
                });

                
                if (report.pid === 3) {
                    startRow = worksheet.lastRow.number + 5; // Add more space for pid = 3
                } else {
                    startRow = worksheet.lastRow.number + 4;  // Regular spacing for other pids
                }
            });

            // Style all headers and tables, but skip borders for title rows
            worksheet.eachRow((row, rowNumber) => {
                if (rowNumber > 1 && !worksheet.getCell(`A${rowNumber}`).value?.startsWith('FLAT NO.')) { // Skip the title rows (Flat No, Plaza Name, Date)
                    row.eachCell((cell) => {
                        cell.alignment = { horizontal: 'center' };
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    });
                }
            });

            // Auto-size columns
            worksheet.columns.forEach(column => {
                const maxLength = column.values.reduce((acc, curr) => {
                    const valueLength = curr ? curr.toString().length : 0;
                    return Math.max(acc, valueLength);
                }, 10);
                column.width = maxLength + 2;
            });

            // Generate the Excel file and trigger the download
            worksheet.columns = worksheet.columns.map((col) => ({ width: 10.7 }));
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), `${xlsxfilename}.xlsx`);
        } catch (error) {
            console.error('Error generating Excel file:', error);
        }
    };

    return (
        <button className="btn btn-success mb-3" onClick={handleExport}>
            Export to Excel
        </button>
    );
};

export default ExportMonthlyReport;
