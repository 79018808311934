import React, { useState } from 'react';
import { createUser } from '../../services/apiServices';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import '../../styles/global.css';

const UserForm = ({ onUserCreated }) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const result = await createUser( {name,email,password,});
      if (result.error) {
        swal({ text: result.error, icon: "warning", dangerMode: true });
      } else {
        swal("Success!", result.message, "success");
        navigate('/login');
      }
    }
  };
   function handleReset() {
    setName(''); setEmail(''); setPassword(''); setEmailError(null); setNameError(null); setPasswordError(null);
   } 
   const handleValidation = () => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    handleReset();
    if(!name){
      setNameError('Please enter name')
      return false
    } else if(!email){
      setEmailError('Please enter email')
      return false  
    } else if(!pattern.test(email)){
      setEmailError('Please enter email')
      return false 
    }  else if(!password){
      setPasswordError('Please enter password')
      return false  
    } else{
      return true
    }
  }
   const handleRedirect = () => {
    navigate('/login');
  };

  return (
    <div className='user-page h-100'>
    <div className="row w-100">
    <div className='container p-5'>
    <div className="form-box">
      <h2>Create Account</h2>
      <form className='my-4'>
        <input type="text" value={name} placeholder="Your name" required onChange={(e) => setName(e.target.value)} />
        {nameError && <><small className="error">{nameError}</small></>}
        <input type="text" value={email} placeholder="Email" required onChange={(e) => setEmail(e.target.value)} />
        {emailError && <><small className="error">{emailError}</small></>}
        <input type={showPassword ? "text" : "password"} value={password} placeholder="Password" required onChange={(e) => setPassword(e.target.value)} />
        {passwordError && <><small className="error">{passwordError}</small></>}
        <div className='mt-2'>
          <label className='text-white size-18'>
          <input type="checkbox" checked={showPassword} onChange={(e) => setShowPassword(e.target.checked)} />
          Show Password
          </label>
        </div>
        <div className="button-group mt-3">
          <button type="submit" className="register-btn" onClick={handleSubmit}>Register</button>
          <button type="reset" className="reset-btn" onClick={handleRedirect}>Login</button>
        </div>
      </form>
    </div>
    </div>
  </div>
  </div>
  );
};

export default UserForm;
